'use strict';

const particlesJS = global.particlesJS;

let colors = [
    '#E0001A',
    '#FA8E1A',
    '#2E3642'
];

particlesJS('stars', {
    'particles': {
        'number': {
            'value': 150
        },
        'shape': {
            'type': 'circle'
        },
        'size': {
            'value': 10,
            'random': true
        },
        'line_linked': {
            'enable': false
        },
        'color': {
            'value': colors
        },
        'move': {
            'enable': true,
            'speed': 0.6,
            'direction': 'none',
            'straight': false,
            'out_mode': 'bounce'
        }
    },
    'interactivity': {
        'detect_on': 'canvas',
        'events': {
            'onhover': {
                'enable': false
            }
        },
        'modes': {
            'push': {
                'particles_nb': 10
            }
        }
    }
});
