'use strict';

const $ = global.$;

module.exports = {
    template: '#news',
    props: ['news'],
    mounted: function () {
        $('.bxslider').bxSlider({
            auto: true,
            pause: 6000,
            pager: false,
            controls: false,
            responsive: false,
            preloadImages: 'all',
            touchEnabled: false
        });
    }
};
