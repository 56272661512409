(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
(function (global){(function (){
'use strict'

const moment = global.moment
const Vue = global.Vue
const $ = global.$

Vue.component('hours', require('./components/hours'))
Vue.component('news', require('./components/news'))
Vue.component('error', require('./components/error'))

new Vue({ // eslint-disable-line
  el: '#app',
  data: {
    news: [],
    hours: [],
    error: false,
    now: { type: Date, default: Date.now }
  },
  mounted: function () {
    require('./particles')

    const that = this

    setInterval(function () {
      that.now = Date.now()
    }, 1000)

    if (global.io) {
      const socket = global.io($('#app').data('socket'))

      socket.on('data', function (data) {
        if (data && Array.isArray(data.hours)) {
          that.hours = data.hours
        }
        if (data && Array.isArray(data.news)) {
          that.news = data.news
        }
      })
    }
  },
  methods: {
    displayDate: function () {
      return moment(this.now).format('dddd Do MMMM').toUpperCase()
    },
    type: function () {
      if (this.hours.length > 0) {
        return 'hours'
      }
      if (this.news.length > 0) {
        return 'news'
      }

      return 'error'
    },
    is: function (type) {
      return this.type() === type
    },
    title: function () {
      if (this.is('hours')) {
        return 'Programme'
      }
      if (this.is('news')) {
        return 'À la Une'
      }

      return 'Bienvenue'
    }
  }
})

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{"./components/error":2,"./components/hours":3,"./components/news":4,"./particles":5}],2:[function(require,module,exports){
'use strict';

module.exports = {
    template: '#error'
};

},{}],3:[function(require,module,exports){
(function (global){(function (){
'use strict';

const moment = global.moment;

module.exports = {
    template: '#hours',
    props: ['hours', 'now'],
    watch: {
        now: function () {
            let isFirst = true;
            let hours = JSON.parse(JSON.stringify(this.hours));
            hours.reverse();
            hours = hours.map(function (hour) {
                hour.classes = [];
                if (moment(hour.hour).isBefore()) {
                    hour.classes.push('passed');

                    if (isFirst) {
                        hour.classes.push('current');
                        isFirst = false;
                    }
                }

                return hour;
            });

            hours.reverse();

            this.$parent.hours = hours;
        }
    },
    methods: {
        getTimestamp: function (hour) {
            return moment(hour.hour).format('X');
        },
        getDate: function (hour) {
            return moment(hour.hour).format('HH[h]mm');
        }
    }
};

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{}],4:[function(require,module,exports){
(function (global){(function (){
'use strict';

const $ = global.$;

module.exports = {
    template: '#news',
    props: ['news'],
    mounted: function () {
        $('.bxslider').bxSlider({
            auto: true,
            pause: 6000,
            pager: false,
            controls: false,
            responsive: false,
            preloadImages: 'all',
            touchEnabled: false
        });
    }
};

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{}],5:[function(require,module,exports){
(function (global){(function (){
'use strict';

const particlesJS = global.particlesJS;

let colors = [
    '#E0001A',
    '#FA8E1A',
    '#2E3642'
];

particlesJS('stars', {
    'particles': {
        'number': {
            'value': 150
        },
        'shape': {
            'type': 'circle'
        },
        'size': {
            'value': 10,
            'random': true
        },
        'line_linked': {
            'enable': false
        },
        'color': {
            'value': colors
        },
        'move': {
            'enable': true,
            'speed': 0.6,
            'direction': 'none',
            'straight': false,
            'out_mode': 'bounce'
        }
    },
    'interactivity': {
        'detect_on': 'canvas',
        'events': {
            'onhover': {
                'enable': false
            }
        },
        'modes': {
            'push': {
                'particles_nb': 10
            }
        }
    }
});

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{}]},{},[1])

