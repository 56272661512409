'use strict'

const moment = global.moment
const Vue = global.Vue
const $ = global.$

Vue.component('hours', require('./components/hours'))
Vue.component('news', require('./components/news'))
Vue.component('error', require('./components/error'))

new Vue({ // eslint-disable-line
  el: '#app',
  data: {
    news: [],
    hours: [],
    error: false,
    now: { type: Date, default: Date.now }
  },
  mounted: function () {
    require('./particles')

    const that = this

    setInterval(function () {
      that.now = Date.now()
    }, 1000)

    if (global.io) {
      const socket = global.io($('#app').data('socket'))

      socket.on('data', function (data) {
        if (data && Array.isArray(data.hours)) {
          that.hours = data.hours
        }
        if (data && Array.isArray(data.news)) {
          that.news = data.news
        }
      })
    }
  },
  methods: {
    displayDate: function () {
      return moment(this.now).format('dddd Do MMMM').toUpperCase()
    },
    type: function () {
      if (this.hours.length > 0) {
        return 'hours'
      }
      if (this.news.length > 0) {
        return 'news'
      }

      return 'error'
    },
    is: function (type) {
      return this.type() === type
    },
    title: function () {
      if (this.is('hours')) {
        return 'Programme'
      }
      if (this.is('news')) {
        return 'À la Une'
      }

      return 'Bienvenue'
    }
  }
})
