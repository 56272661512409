'use strict';

const moment = global.moment;

module.exports = {
    template: '#hours',
    props: ['hours', 'now'],
    watch: {
        now: function () {
            let isFirst = true;
            let hours = JSON.parse(JSON.stringify(this.hours));
            hours.reverse();
            hours = hours.map(function (hour) {
                hour.classes = [];
                if (moment(hour.hour).isBefore()) {
                    hour.classes.push('passed');

                    if (isFirst) {
                        hour.classes.push('current');
                        isFirst = false;
                    }
                }

                return hour;
            });

            hours.reverse();

            this.$parent.hours = hours;
        }
    },
    methods: {
        getTimestamp: function (hour) {
            return moment(hour.hour).format('X');
        },
        getDate: function (hour) {
            return moment(hour.hour).format('HH[h]mm');
        }
    }
};
